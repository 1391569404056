import React, { useState } from 'react'
import content from '../data/content.json'
import arrow from "../assets/arrow.png"
export default function Sidebar({ selectedItem }) {
    const [show, setShow] = useState('')
    const [selectedItemState, setSelectedItemState] = useState('')

    const setOpenMenu = (id) => {
        if (show === id)
            setShow('')
        else
            setShow(id)
    }
    return (
        <aside className="bg-gray-800 text-white w-80 min-h-screen max-h-screen overflow-auto">
            <nav className=''>
                <ol className=" text-gray-500 dark:text-gray-400">
                    {content && content.map((con, key) =>
                        <li key={key} className='' >
                            <button className={`flex border-b-2 border-white justify-between w-full p-2 ${show === key ? 'bg-gray-500' : ''} hover:bg-gray-500`} onClick={() => setOpenMenu(key)}>
                                <span className="text-white font-bold text-sm text-left">{con.title}</span>
                                <img style={{ width: 25 }} alt='course deatisl' className={show === key ? 'rotate-90' : ''} src={arrow} />
                            </button>
                            {show === key && <ul className="ps-5 mt-2 mb-2 list-inside">
                                {
                                    con.chapter && con.chapter.map((chapter, chapterKey) =>
                                        <li>
                                            <span className="text-gray-200 font-bold text-sm">{chapter.title}</span>
                                            <ul className="ps-5 mb-4">
                                                {
                                                    chapter.lesson && chapter.lesson.map((lesson, lessonKey) =>
                                                        <li>
                                                            <button className='cursor-pointer text-left hover:underline' onClick={() => {
                                                                if (chapter.isQuiz) {
                                                                    selectedItem(key, chapterKey, lessonKey, 'quiz')
                                                                    return
                                                                }
                                                                if (chapter.isLab) {
                                                                    selectedItem(key, chapterKey, lessonKey, 'lab')
                                                                    return
                                                                }
                                                                selectedItem(key, chapterKey, lessonKey, 'img')
                                                                setSelectedItemState(`${chapterKey}+${key}+${lessonKey}`)
                                                            }}>
                                                                <span className={` text-xs justify-start ${`${chapterKey}+${key}+${lessonKey}`===selectedItemState ? 'text-blue-300': 'text-white'}`}>{lessonKey + 1}. {lesson.title}</span>
                                                            </button>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                    )
                                }
                            </ul>}
                        </li>
                    )}
                </ol>
            </nav>
        </aside>
    )
}
