import { useState, useEffect } from 'react';
import './App.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


import Layout from './components/Layout';
import Sidebar from './components/Sidebar';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

function App() {
  const [contentUrl, setContentUrl] = useState('');
  const [contentType, setContentType] = useState('img');
  const [numPages, setNumPages] = useState();

  const [showPage, setShowPage] = useState('0');

  useEffect(() => {
    let access = localStorage.getItem('acceess');
    if(access==="12123"){
      setShowPage(true);
    }else{
      setShowPage(false);
      localStorage.setItem('acceess', 'false');
    }

  }, []);

  const setContentMain = (key, chapterKey, lessonKey, type) => {
    setContentType(type)
    var extention = 'png';
    if (type === 'lab') {
      extention = 'pdf'
    }
    const imageUrl = `/assets/${key + '-' + chapterKey + '-' + lessonKey}.${extention}`;
    setContentUrl(imageUrl);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    showPage ? <Layout>
      <div className='flex overflow-hidden'>
        <Sidebar selectedItem={setContentMain} />
        <main className="container mx-auto p-4 min-h-screen max-h-screen overflow-auto">
          {contentUrl && contentType === 'img' && <img className='w-full' alt='course deatisl' src={contentUrl} />}
          {contentUrl && contentType === 'quiz' && <span>Quiz</span>}
          {contentUrl && contentType === 'lab' && <Document options={options} file={contentUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} />)}
          </Document>}
        </main>
      </div>

    </Layout> : <></>
  );
}

export default App;
