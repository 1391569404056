import React from 'react'

export default function Layout({children}) {
    return (
        <body className="bg-gray-100">
            {/* <nav className="bg-blue-500 p-4 flex items-center justify-between">
                <div>
                    <h1 className="text-white text-xl font-semibold">NODEJS SERVICE DEVELOPMENT</h1>
                </div>
                <div className="flex items-center space-x-4">
                    <span className="text-white">Nazmul Tanvir</span>
                    <i className="fas fa-user-circle text-white text-2xl"></i>
                </div>
            </nav> */}
                {children}
        </body>
    )
}
